import classNames from "classnames";
import type { CreateAccountHiveStepProps } from "../HiveCreateAccountModal";
import { toast } from "react-toastify";
import { useAppStore } from "~/store";
import OTPInput from "~/components/OTPInput";
import { m } from "framer-motion";
import PhoneInput from "react-phone-input-2";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "@remix-run/react";
import { RecaptchaVerifier } from "firebase/auth";
import { sendPhoneMessage } from "~/utils/firebase";
import { signInfraUser } from "~/utils/infra";
import { auth } from "~/routes/signup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useGetRedirectTo } from "~/routes/login";
import { getRefFromCookie } from "~/utils/ref";

export default function PhoneVerification({
  createAccountState,
  setCreateAccountState
}: CreateAccountHiveStepProps) {
  const isDarkMode = useAppStore(store => store.settings.dark);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectTo = useGetRedirectTo();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [codeSent, setCodeSent] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      try {
        (window as any).recaptchaVerifier = new RecaptchaVerifier(
          "sign-in-button",
          {
            size: "invisible",
            callback: response => {
              setCodeSent(true);

              setTimeout(() => {
                setCodeSent(false);
              }, 1000);
            }
          },
          auth
        );
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const referral = useMemo(
    () => searchParams.get("referral") || getRefFromCookie() || "leofinance",
    [searchParams]
  );

  const signupWithPhone = () => {
    if (
      !(window as any)?.confirmationResult ||
      !createAccountState?.username ||
      !createAccountState?.generated_keys
    ) {
      return toast(
        "There is an error occurred while creating your account, please refresh and try again.",
        {
          type: "error",
          theme: isDarkMode ? "dark" : "light",
          autoClose: 3_000
        }
      );
    }

    (window as any).confirmationResult
      .confirm(verificationCode)
      .then(async user => {
        const firebase_id_token = await user.user.getIdToken();

        signInfraUser({
          username: createAccountState.username,
          firebase_uid: user.user.uid,
          firebase_id_token: firebase_id_token,
          public: {
            posting_key: createAccountState.generated_keys?.public.posting,
            owner_key: createAccountState.generated_keys?.public.ownerKey,
            active_key: createAccountState.generated_keys?.public.active
          },
          memo_key: createAccountState.generated_keys?.memo,
          referral: referral ?? ""
        }).then(_response => {
          const response = JSON.parse(JSON.stringify(_response));

          if (response[0]) {
            (window as any).username = createAccountState.username;
            (window as any).generated_keys = createAccountState.generated_keys;

            return navigate(`/signup/success?redirectTo=${redirectTo}`);
          }

          toast(response[1], {
            type: "error",
            theme: isDarkMode ? "dark" : "light",
            autoClose: 3_000
          });
        });
      });
  };

  return (
    <m.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-1 flex-col w-full gap-y-6"
    >
      <div className="flex flex-1 flex-row flex-wrap gap-4">
        <div className="flex flex-col w-full gap-y-1">
          <label
            htmlFor="phone-number"
            className="font-medium text-sm text-pri dark:text-pri-d pl-px"
          >
            Phone number
          </label>

          <div className="relative flex flex-1 items-center">
            <PhoneInput
              country={"us"}
              placeholder="+1 912 345-6789"
              buttonClass="bg-pri dark:bg-pri-d border-pri dark:border-pri-d"
              inputClass="h-10 bg-pri dark:bg-pri-d rounded-lg border border-pri dark:border-pri-d outline outline-2 outline-transparent transition-colors duration-150 focus:outline-pri-d dark:focus:outline-pri"
              dropdownClass="bg-pri dark:bg-pri-d rounded-lg border border-pri dark:border-pri-d shadow-[0_0_12px_3px_rgb(255_255_255_/_17%)]"
              value={phoneNumber}
              onChange={value => setPhoneNumber(value)}
            />
          </div>
        </div>

        <div className="flex flex-col w-full gap-y-1">
          <label
            htmlFor="verification-code"
            className="font-medium text-sm text-pri dark:text-pri-d pl-px"
          >
            Verification Code
          </label>
          <div className="relative flex flex-1 items-center gap-x-4">
            <OTPInput value={verificationCode} onChange={setVerificationCode} />
            <button
              type="button"
              title="Send Code"
              aria-label="Send Code"
              className={classNames(
                "py-1 px-2 rounded-full text-xs font-medium cursor-pointer transition-all duration-150 hover:opacity-90",
                {
                  "bg-pri-d dark:bg-pri text-pri-d dark:text-pri": !codeSent,
                  "bg-green-500 text-pri": codeSent
                }
              )}
              disabled={codeSent}
              onClick={() => sendPhoneMessage(phoneNumber)}
            >
              {codeSent ? (
                <span className="flex items-center gap-x-2">
                  <FontAwesomeIcon icon={faCheck} size="xs" />
                  Sent
                </span>
              ) : (
                "Send Code"
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-end gap-x-1">
        <button
          type="button"
          aria-label="Go Back"
          className="flex items-center w-fit py-2.5 px-5 rounded-full border border-pri dark:border-pri-d text-sm font-medium hover:bg-pri-d/[0.075] dark:hover:bg-pri/[0.075] transition-colors duration-150"
          onClick={() =>
            setCreateAccountState(
              "current_step",
              createAccountState.current_step - 1
            )
          }
        >
          Go back
        </button>

        <button
          type="button"
          aria-label="Sign up"
          className="flex items-center justify-center w-fit min-h-[42px] min-w-[108px] py-2 px-6 rounded-full bg-pri-d dark:bg-pri text-pri-d dark:text-pri text-sm font-medium hover:opacity-80 transition-opacity duration-150 disabled:opacity-50 disabled:hover:opacity-50 disabled:cursor-not-allowed"
          disabled={verificationCode?.length !== 6}
          onClick={() => signupWithPhone()}
        >
          Sign up
        </button>
      </div>

      <span
        id="sign-in-button"
        className="fixed bottom-0 right-0 opacity-0 pointer-events-none"
      />
    </m.main>
  );
}

