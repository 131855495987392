import classNames from "classnames";
import { useContext, useState } from "react";
import { Tooltip } from "react-tooltip";
import { useAppStore } from "~/store";

interface PopoverTypes {
  anchorId: string;
  content: string;
  place?: "top" | "bottom" | "left" | "right";
  style?: any;
  className?: string;
}

const defaultStyles = {
  fontSize: 12,
  fontWeight: 500,
  backgroundColor: "#3f3f46",
  padding: ".325rem .625rem"
};

function Popover(props: PopoverTypes) {
  const isDarkMode = useAppStore(store => store.settings.dark);
  const [open, setOpen] = useState(false);

  return (
    <Tooltip
      // anchorId={props.anchorId.replaceAll(/[^a-zA-Z0-9\s]/g, "")}
      anchorId={props.anchorId}
      content={props.content}
      place={props.place}
      style={{ ...props.style, ...(open && { opacity: 1 }) } ?? defaultStyles}
      className={classNames(
        "py-2 px-3.5 border border-pri dark:border-pri-d bg-pri dark:bg-pri-d !rounded-xl text-pri dark:text-pri-d text-xxs leading-normal shadow-[0_0_12px_3px_rgb(255_255_255_/_12%)] !backdrop-blur",
        props.className
      )}
      noArrow={true}
      variant={isDarkMode ? "dark" : "light"}
      isOpen={open}
      setIsOpen={setOpen}
    />
  );
}

export default Popover;

