import {
  faAt,
  faCheck,
  faInfoCircle,
  faPhone,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { SocialLinkIcons } from "../SocialLink";
import classNames from "classnames";
import type { CreateAccountHiveStepProps } from "../HiveCreateAccountModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generateRandomPassword } from "~/utils/single/generateRandomPassword";
import { generateFileFromText, generateKeys } from "~/utils/hive";
import { toast } from "react-toastify";
import { useAppStore } from "~/store";
import { m } from "framer-motion";
import useAccountNameValid from "~/hooks/useAccountNameValid";
import { useMemo, useState } from "react";
import { verifyTwitter } from "~/utils/firebase";
import { loginInfraUser, signInfraUser } from "~/utils/infra";
import { useNavigate, useSearchParams } from "@remix-run/react";
import { useGetRedirectTo } from "~/routes/login";
import { getRefFromCookie } from "~/utils/ref";

export default function ChooseUsername({
  createAccountState,
  setCreateAccountState,
  setVisibility
}: CreateAccountHiveStepProps) {
  const isDarkMode = useAppStore(store => store.settings.dark);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const redirectTo = useGetRedirectTo();

  const [isValid, isInvalid, isFetching] = useAccountNameValid(
    createAccountState.username
  );

  const [agreedo, setAgreedo] = useState(false);

  const referral = useMemo(
    () => searchParams.get("referral") || getRefFromCookie() || "leofinance",
    [searchParams]
  );

  const handleDownloadKeys = () => {
    if (isInvalid || createAccountState.username.length < 3) {
      return;
    }

    if (createAccountState.keys_downloaded) {
      if (!createAccountState.verification_method) {
        toast("Please select a verification method", {
          type: "error",
          autoClose: 3000,
          theme: isDarkMode ? "dark" : "light"
        });

        return;
      }

      if (!agreedo) return;

      if (createAccountState.verification_method === "twitter") {
        if (createAccountState.generated_keys === null) {
          return toast(
            "There is an error occurred while creating your account, please reload and try again",
            {
              type: "error",
              theme: isDarkMode ? "dark" : "light",
              autoClose: 3_000
            }
          );
        }

        return verifyTwitter().then(async user => {
          const firebase_id_token = await user.user.getIdToken();

          signInfraUser({
            username: createAccountState.username.toLowerCase(),
            firebase_uid: user.user.uid,
            firebase_id_token: firebase_id_token,
            public: {
              posting_key: createAccountState.generated_keys!.public.posting,
              owner_key: createAccountState.generated_keys!.public.ownerKey,
              active_key: createAccountState.generated_keys!.public.active
            },
            private: {
              posting_key:
                createAccountState.generated_keys!.private.postingKey,
              owner_key: createAccountState.generated_keys!.private.ownerKey,
              active_key: createAccountState.generated_keys!.private.activeKey
            },
            memo_key: createAccountState.generated_keys!.memo,
            referral: referral ?? "",
            password: createAccountState.generated_keys!.password
          }).then(_response => {
            const response = JSON.parse(JSON.stringify(_response));
            if (response[0]) {
              loginInfraUser({
                firebase_id_token: firebase_id_token
              }).then(_response => {
                const response = JSON.parse(JSON.stringify(_response));

                if (response[0]) {
                  window.username = createAccountState.username;
                  window.generatedKeys = createAccountState.generated_keys;
                  return navigate(`/signup/success?redirectTo=${redirectTo}`);
                }

                toast(response[1], {
                  type: "error",
                  theme: "dark",
                  autoClose: 3000
                });
              });
            }

            toast(response[1], {
              type: response[0] ? "success" : "error",
              theme: "dark",
              draggable: false,
              closeOnClick: false
            });
          });
        });
      }

      setCreateAccountState(
        "current_step",
        createAccountState.current_step + 1
      );

      return;
    }

    const randomPassword = generateRandomPassword(16);
    const generatedKeys = generateKeys(
      createAccountState.username,
      randomPassword
    );
    const element = document.createElement("a");
    const file = new Blob(
      [generateFileFromText(generatedKeys, createAccountState.username)],
      {
        type: "text/plain"
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = createAccountState.username + "_hive_keys.txt";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    setCreateAccountState("generated_keys", generatedKeys);
    setCreateAccountState("keys_downloaded", true);
  };

  return (
    <m.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-1 flex-col w-full gap-y-6"
    >
      <div className="flex items-start gap-x-3 max-w-[520px] py-3 px-4 bg-red-500/10 border border-red-500/50 rounded-xl">
        <FontAwesomeIcon
          icon={faInfoCircle}
          size="sm"
          fixedWidth
          className="text-red-500 mt-1"
        />
        <p className="text-sm text-red-500">
          Once you verify your username with the selected verification method,
          you <strong>can't</strong> change it or continue the sign-up proccess
          with another username with same verification method.
        </p>
      </div>

      <div className="flex flex-1 flex-row flex-wrap gap-4">
        <div className="flex flex-col w-full gap-y-1">
          <label
            htmlFor="username"
            className="font-medium text-sm text-pri dark:text-pri-d pl-px"
          >
            Choose an username
          </label>

          <div className="relative flex flex-1 items-center">
            <FontAwesomeIcon
              icon={faAt}
              size="sm"
              className="absolute left-3.5 text-pri/60 dark:text-pri-d/60"
            />

            <input
              id="username"
              type="text"
              className={classNames(
                "w-full py-2.5 pr-3 pl-9 rounded-lg bg-pri dark:bg-pri-d border text-sm font-medium outline-2 outline-transparent outline-offset-1 placeholder:text-pri/40 dark:placeholder:text-pri-d/40 transition-all duration-150",
                {
                  "border-pri dark:border-pri-d focus:outline-pri-d dark:focus:outline-pri":
                    !isInvalid && !isValid,
                  "border-green-500 dark:border-green-500 focus:outline-green-500 dark:focus:outline-green-500":
                    isValid,
                  "border-red-500 dark:border-red-500 focus:outline-red-500 focus:dark:outline-red-500":
                    isInvalid
                }
              )}
              placeholder="CoolGuy451"
              value={createAccountState.username}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCreateAccountState("username", e.target.value)
              }
              maxLength={16}
              autoFocus
            />

            {isFetching && (
              <span className="absolute right-3">
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="sm"
                  className="text-pri/70 dark:text-pri-d/70 animate-spin"
                />
              </span>
            )}

            {isValid && !isFetching && (
              <span className="absolute right-3">
                <FontAwesomeIcon
                  icon={faCheck}
                  size="sm"
                  className="text-green-500 dark:text-green-500"
                />
              </span>
            )}
          </div>

          {isInvalid && (
            <small className="text-xs text-red-500">
              This username is already taken or contains special characters.
            </small>
          )}
        </div>

        <div className="flex flex-col w-full gap-y-1">
          <label className="font-medium text-sm text-pri dark:text-pri-d pl-px">
            Verification method
          </label>
          <div className="flex items-center gap-x-1">
            <button
              type="button"
              className={classNames(
                "flex justify-center items-center px-5 py-2 gap-x-2.5 rounded-full border border-pri dark:border-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] text-sm font-medium transition-colors duration-150",
                {
                  "bg-pri dark:bg-pri-d":
                    createAccountState.verification_method !== "twitter",
                  "bg-pri-d/10 dark:bg-pri/10":
                    createAccountState.verification_method === "twitter"
                }
              )}
              onClick={() =>
                setCreateAccountState("verification_method", "twitter")
              }
            >
              <span className="w-5 h-5">{SocialLinkIcons["twitter"]}</span>X
            </button>

            <button
              type="button"
              disabled={true}
              className={classNames(
                "flex justify-center opacity-50 items-center px-5 py-2 gap-x-2.5 rounded-full border border-pri dark:border-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] text-sm font-medium transition-colors duration-150",
                {
                  "bg-pri dark:bg-pri-d":
                    createAccountState.verification_method !== "phone",
                  "bg-pri-d/10 dark:bg-pri/10":
                    createAccountState.verification_method === "phone"
                }
              )}
              onClick={() =>
                setCreateAccountState("verification_method", "phone")
              }
            >
              <FontAwesomeIcon icon={faPhone} size="sm" />
              Phone Number
            </button>
          </div>
        </div>

        <div
          className={classNames(
            "flex items-center gap-x-3 pt-1.5 transition-colors duration-150",
            {
              "text-pri/60 dark:text-pri-d/60":
                !createAccountState.keys_downloaded,
              "text-green-500": createAccountState.keys_downloaded
            }
          )}
        >
          <span className="flex pl-1">
            {!createAccountState.keys_downloaded ? (
              <FontAwesomeIcon
                icon={faSpinner}
                size="sm"
                className="animate-spin opacity-80"
                fixedWidth
              />
            ) : (
              <FontAwesomeIcon icon={faCheck} size="sm" fixedWidth />
            )}
          </span>
          <small className="text-sm leading-tight">
            Before to continue, you have to select username and{" "}
            <b>download your keys</b> and save them for your account's security.
          </small>
        </div>
      </div>

      {createAccountState.keys_downloaded && (
        <label
          htmlFor="agree"
          className={classNames(
            "group flex items-center gap-x-3 pl-1 cursor-pointer hover:text-pri dark:hover:text-pri-d transition-colors duration-150",
            {
              "text-pri/70 dark:text-pri-d/70": !agreedo,
              "text-pri dark:text-pri-d": agreedo
            }
          )}
        >
          <input
            id="agree"
            type="checkbox"
            className="opacity-0"
            checked={agreedo}
            onChange={event => setAgreedo(event.target.checked)}
            hidden
          />

          <span
            className={classNames(
              "flex shrink-0 items-center justify-center w-5 h-5 rounded border border-pri dark:border-pri-d group-hover:bg-pri-d/[.075] dark:group-hover:bg-pri/[.075] cursor-pointer transition-colors duration-150",
              {
                "bg-pri-d/[.075] dark:bg-pri/[.075]": agreedo
              }
            )}
          >
            {agreedo && <FontAwesomeIcon icon={faCheck} size="xs" fixedWidth />}
          </span>

          <span className="text-xs leading-tight">
            I securely stored my keys and I understand if I lost my keys, my
            account <strong>cannot</strong> be recovered.{" "}
            <span className="text-red-500">*</span>
          </span>
        </label>
      )}

      <div className="flex items-center justify-end gap-x-1">
        <button
          type="button"
          aria-label="Cancel"
          className="flex items-center w-fit py-2.5 px-5 rounded-full border border-pri dark:border-pri-d text-sm font-medium hover:bg-pri-d/[0.075] dark:hover:bg-pri/[0.075] transition-colors duration-150"
          onClick={() => setVisibility(false)}
        >
          Cancel
        </button>

        <button
          type="button"
          aria-label="Download Keys"
          className="flex items-center justify-center w-fit min-h-[42px] min-w-[108px] py-2 px-6 rounded-full bg-pri-d dark:bg-pri text-pri-d dark:text-pri text-sm font-medium hover:opacity-80 transition-opacity duration-150 disabled:opacity-50 disabled:hover:opacity-50 disabled:cursor-not-allowed"
          disabled={
            createAccountState.username.length < 3 ||
            isInvalid ||
            (createAccountState.keys_downloaded && !agreedo)
          }
          onClick={() => handleDownloadKeys()}
        >
          {!createAccountState.keys_downloaded ? "Download Keys" : "Continue"}
        </button>
      </div>
    </m.main>
  );
}

